<template></template>

<script>
export default {
  mounted() {
    this.$router.push({
      name: "login",
    });
  },
};
</script>

<style>
</style>